import React from 'react';
import { FormattedMessage } from 'react-intl';
import { unauthAuditProgress } from '../../utils/pageUtils';
import { logEvent, OPEN_SIGNUP } from '../../utils/amplitude';
import section6Animation from '../../images/about/animations/section-6-animation.png';

const Section6 = () => {
  const toggleSignUp = () => {
    localStorage.setItem('signUp', 'open');
    logEvent(OPEN_SIGNUP, { page: '' });
    unauthAuditProgress('', 'open_signup');
    window.scrollTo({
      top: window.pageYOffset + 1,
    });
  };
  return (
    <div className="section-6">
      <div className="flex-row">
        <div className="half-column">
          <div className="hero">
            <h1>
              <FormattedMessage
                id="about.lifeIsFilled"
                defaultMessage="Life is filled with uncertainty, complexity, and ambiguity."
              />
            </h1>
            <p>
              <FormattedMessage
                id="about.weAimTo"
                defaultMessage="At Inkblot, we aim to help you feel motivated and at your best. Find your care provider and learn how to cope with life’s challenges."
              />
            </p>
            <div className="button">
              <button className="green-blue-button" onClick={toggleSignUp}>
                <FormattedMessage
                  id="about.feelingBetterNow"
                  defaultMessage="Start Feeling Better Now"
                />
              </button>
            </div>
          </div>
        </div>
        <div className="half-column">
          <img src={section6Animation} alt="Canadian video counselling" />
        </div>
      </div>
    </div>
  );
};

Section6.propTypes = {};

export default Section6;
