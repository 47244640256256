import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

const Section3 = () => (
  <div className="section-3">
    <h1>
      <FormattedHTMLMessage
        id="about.ourMission"
        defaultMessage="It’s our mission to provide <span class='regular'>effective</span>, results driven mental healh services."
      />
    </h1>
  </div>
);

Section3.propTypes = {};

export default Section3;
