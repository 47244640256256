import React from 'react';
import { Link } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import careers1 from '../../images/about/people/careers-1.png';
import careers2 from '../../images/about/people/careers-2.png';

const Section5 = () => (
  <div className="section-5">
    <img className="careers-1" src={careers1} alt="Careers at Inkblot 1" />
    <div className="header">
      <p className="sub-header">
        <FormattedMessage
          id="about.joinUsOn"
          defaultMessage="Join us on our mission"
        />
      </p>
      <h1>
        <FormattedMessage
          id="about.careersAtInkblot"
          defaultMessage="Careers at Inkblot"
        />
      </h1>
      <p>
        <FormattedMessage
          id="about.wereGrowing"
          defaultMessage="We're constantly growing, creating new challenges for our team to accomplish. At Inkblot we value professional growth and want you to reach your full potential."
        />
      </p>
      <div className="button">
        <Link to="/careers" className="orange-button" tabIndex={0}>
          <FormattedMessage
            id="about.meetTheTeam "
            defaultMessage="Meet The Team"
          />
        </Link>
        <Link
          to="https://jobs.lever.co/inkblottherapy"
          target="_blank"
          rel="noopener noreferrer"
          tabIndex={0}
          className="orange-button open-button"
        >
          <FormattedMessage
            id="about.openPositions"
            defaultMessage="Open Positions"
          />
        </Link>
      </div>
    </div>
    <img className="careers-2" src={careers2} alt="Careers at Inkblot 2" />
  </div>
);

export default Section5;
