import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import Section1 from '../pagePartials/about/Section1';
import Section2 from '../pagePartials/about/Section2';
import Section3 from '../pagePartials/about/Section3';
import Section4 from '../pagePartials/about/Section4';
import Section5 from '../pagePartials/about/Section5';
import Section6 from '../pagePartials/about/Section6';

const About = props => (
  <Layout location={props.location}>
    <div className="about-page" role="main">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
    </div>
  </Layout>
);

About.propTypes = {
  location: PropTypes.object.isRequired,
};

export default About;
